const __pages_import_0__ = () => import("/pages/usuarios.vue");
const __pages_import_1__ = () => import("/pages/usuarios/novo.js");
const __pages_import_2__ = () => import("/pages/usuarios/index.vue");
const __pages_import_3__ = () => import("/pages/usuarios/editar/[id].vue");
const __pages_import_4__ = () => import("/pages/lojas.vue");
const __pages_import_5__ = () => import("/pages/lojas/novo.js");
const __pages_import_6__ = () => import("/pages/lojas/index.vue");
const __pages_import_7__ = () => import("/pages/lojas/editar/[id].vue");
const __pages_import_8__ = () => import("/pages/login.vue");
const __pages_import_9__ = () => import("/pages/login/recover.vue");
const __pages_import_10__ = () => import("/pages/login/index.vue");
import __pages_import_11__ from "/pages/index.vue";
const __pages_import_12__ = () => import("/pages/home.vue");
const __pages_import_13__ = () => import("/pages/home/index.vue");
const __pages_import_14__ = () => import("/pages/contagens.vue");
const __pages_import_15__ = () => import("/pages/contagens/novo.js");
const __pages_import_16__ = () => import("/pages/contagens/index.vue");
const __pages_import_17__ = () => import("/pages/contagens/editar/[id].vue");

const routes = [{"path":"/usuarios","component":__pages_import_0__,"children":[{"name":"usuarios-novo","path":"novo","component":__pages_import_1__,"props":true},{"name":"usuarios","path":"","component":__pages_import_2__,"props":true},{"name":"usuarios-editar-id","path":"editar/:id","component":__pages_import_3__,"props":true}],"props":true},{"path":"/lojas","component":__pages_import_4__,"children":[{"name":"lojas-novo","path":"novo","component":__pages_import_5__,"props":true},{"name":"lojas","path":"","component":__pages_import_6__,"props":true},{"name":"lojas-editar-id","path":"editar/:id","component":__pages_import_7__,"props":true}],"props":true},{"path":"/login","component":__pages_import_8__,"children":[{"name":"login-recover","path":"recover","component":__pages_import_9__,"props":true},{"name":"login","path":"","component":__pages_import_10__,"props":true}],"props":true},{"name":"index","path":"/","component":__pages_import_11__,"props":true},{"path":"/home","component":__pages_import_12__,"children":[{"name":"home","path":"","component":__pages_import_13__,"props":true}],"props":true},{"path":"/contagens","component":__pages_import_14__,"children":[{"name":"contagens-novo","path":"novo","component":__pages_import_15__,"props":true},{"name":"contagens","path":"","component":__pages_import_16__,"props":true},{"name":"contagens-editar-id","path":"editar/:id","component":__pages_import_17__,"props":true}],"props":true}];

export default routes;