<template>
    <DefaultLayout>
    </DefaultLayout>
</template>
<script>
import DefaultLayout from "../layouts/Default.vue";
import Logo from '../assets/logo.svg';
export default {
    components: {
        DefaultLayout,
        Logo
    },
    data() {
        return {
            showMenu: false
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        }
    },
    beforeRouteEnter() {
        //this.$router.replace('/contagens');
        
        return '/home';
    }
};
</script>
<style lang="less" scoped>
    svg.logo {
        font-size: 2rem;
        color: white;
    }
</style>