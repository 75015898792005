import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
//import routes from '~pages';
import routes from '~pages'
import api from '../lib/api';

import $store from '../store';

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from) => {

    let isAuthenticated = false;
    try {
        let access_token = localStorage.getItem('access_token');
        let user = JSON.parse(localStorage.getItem('user'));
        if (access_token && user) {
            isAuthenticated = true;
        }
    } catch (err) {
        console.error(err);
    }

    if (!/^\/login/.exec(to.fullPath) && !isAuthenticated) {
        return '/login';
    }

    if (/^\/login/.exec(to.fullPath) && isAuthenticated) {
        return '/';
    }

    if (isAuthenticated) {
        let user = $store.state.user;
        if (user.tipo == 'Auxiliar de Estoque') {
            if (/^\/(produtos|usuarios|lojas)/.exec(to.fullPath)) {
                return '/contagens';
            }
        }
        
        if (user.tipo == 'Administrativo') {
            if (/^\/contagens\/.+/.exec(to.fullPath)) {
                //return '/contagens';
            }
        }
    }

    //next();
});



api.interceptors.response.use(response => {
    return response;
}, err => {
    if (err?.response?.status == 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        //router.replace('/login');
    }
    return Promise.reject(err);
})

export default router;